<template>
  <page :loading="loading">
    <template v-slot:left>
      <logo />
    </template>
    <template v-slot:right>
      <div style="max-width: 500px">
        <div class="text-h4 font-weight-bold mb-1">Get started</div>
        <div class="text-body-2 font-weight-medium mb-10 lightGrey--text">
          Create an account
        </div>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="register.name"
            label="Name"
            dark
            required
            :rules="[rules.name]"
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="register.email"
            label="Email"
            dark
            required
            :rules="[rules.email]"
            autocomplete="off"
          ></v-text-field>
          <v-checkbox
            v-model="register.agreed_compliance_marketing"
            color="secondary"
            dark
            hide-details
          >
            <template v-slot:label>
              <div class="text-caption grey--text text--lighten-2">
                <div>
                  By signing up I agree to the WithU
                  <a
                    target="_blank"
                    href="https://withutraining.com/privacy"
                    @click.stop
                    class="secondary--text"
                  >
                    EULA
                  </a>
                  ,
                  <a
                    target="_blank"
                    href="https://withutraining.com/privacy"
                    @click.stop
                    class="mr-1 secondary--text"
                  >
                    Privacy Policy
                  </a>
                  {{ "& to receive occasional marketing emails." }}
                </div>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-if="dataSharing"
            v-model="register.agreed_data_sharing"
            color="secondary"
            dark
            hide-details
          >
            <template v-slot:label>
              <div class="text-caption grey--text text--lighten-2">
                <div v-if="dataSharing.handlebars">
                  <component :is="dataSharingText"></component>
                </div>
                <div v-else>
                  {{ dataSharing.copy }}
                </div>
              </div>
            </template>
          </v-checkbox>
        </v-form>
        <div class="mt-10">
          <v-btn
            color="secondary"
            rounded
            dark
            class="black--text font-weight-bold"
            @click="submit"
            :disabled="!canSubmit"
            ><span class="px-10">Next</span></v-btn
          >
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "@/rules.js";

export default {
  name: "Register",
  components: {},
  data() {
    return {
      rules: rules,
      register: {
        name: null,
        email: null,
        agreed_compliance_marketing: false,
        agreed_data_sharing: false,
      },
    };
  },
  watch: {
    "register.name": function (value) {
      if (value) this.register.name = value.toUpper();
    },
    "register.email": function (value) {
      if (value) this.register.email = value.toLowerCase();
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    loading() {
      return this.account.loading;
    },
    deal() {
      return this.account.redeem.deal;
    },
    code() {
      return this.account.redeem.code;
    },
    successfulCode() {
      return this.deal != null && this.code != null;
    },
    dataSharing() {
      return this.deal ? this.deal.data_sharing : null;
    },
    dataSharingText() {
      var t = `<div>${this.dataSharing.copy}</div>`;

      var matches = this.dataSharing.copy.match(/{{.*}}/gm);

      for (var m of matches) {
        var key = m.replace("{{", "").replace("}}", "");
        var handlebar = this.dataSharing.handlebars[key];
        var text = handlebar.text;
        var url = handlebar.url;
        if (text != null && url != null) {
          t = t.replace(
            m,
            `<a target="_blank" href="${url}" @click.stop class="mr-1 secondary--text">${text}</a>`
          );
        } else if (text != null) {
          t = t.replace(m, text);
        }
      }

      return {
        template: t,
      };
    },
    canSubmit() {
      var hasData =
        this.register.name != null &&
        this.register.name != "" &&
        this.register.email != null &&
        this.register.email != "" &&
        this.register.agreed_compliance_marketing == true;

      if (!hasData) {
        return false;
      }
      if (this.dataSharing && this.dataSharing.is_required) {
        if (!this.register.agreed_data_sharing) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      registerUser: "account/register",
      setLoading: "account/setLoading",
    }),
    async submit() {
      if (this.$refs.form.validate()) {
        var payload = {
          ...this.register,
        };
        if (this.deal) {
          payload.deal_id = this.deal.id;
          if (this.deal.subscription) {
            payload.offering = this.deal.subscription.offering;
          }
        }
        await this.registerUser(payload);
        this.$router.push("/verify");
      }
    },
  },
  beforeMount() {
    this.setLoading(false);
  },
};
</script>

<style scoped>
</style>

